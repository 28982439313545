var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "d-flex flex-column folders-tree-card",
      attrs: { flat: "", tile: "" }
    },
    [
      _vm.editFolder === null
        ? _c("div", [
            _c("p", { staticClass: "ma-5", attrs: { centered: "" } }, [
              _vm._v("No folder selected")
            ])
          ])
        : _c(
            "div",
            [
              _c(
                "v-tabs",
                {
                  attrs: {
                    dark: "",
                    grow: "",
                    "background-color": "secondary"
                  },
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _c("v-tab", { attrs: { href: "#folderPropertiesTab" } }, [
                    _vm._v("Properties")
                  ])
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _c(
                    "v-tab-item",
                    { attrs: { value: "folderPropertiesTab" } },
                    [
                      _c(
                        "v-container",
                        [
                          !_vm.editFolder.imageUrl
                            ? _c(
                                "v-row",
                                {
                                  staticClass: "pb-3",
                                  attrs: { "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "featured-folder-icon",
                                          attrs: { color: "primary" }
                                        },
                                        [_vm._v("mdi-folder")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.editFolder.imageUrl
                            ? _c(
                                "v-row",
                                {
                                  staticClass: "pb-3",
                                  attrs: { "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-img", {
                                        staticClass: "logo_class",
                                        staticStyle: { "max-width": "400px" },
                                        attrs: {
                                          src: _vm.editFolder.imageUrl,
                                          height: "215px"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.showImageUrl
                            ? _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.imageOptions,
                                          "item-text": "fileName",
                                          "item-value": "fileUrl",
                                          label: "Featured Image",
                                          filled: "",
                                          hint:
                                            "Image to display on the featured folder tile",
                                          "persistent-hint": "",
                                          dense: "",
                                          disabled:
                                            _vm.disableInput ||
                                            !_vm.selectedFolder.userAccess
                                              .canUpdate
                                        },
                                        model: {
                                          value: _vm.editFolder.imageUrl,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.editFolder,
                                              "imageUrl",
                                              $$v
                                            )
                                          },
                                          expression: "editFolder.imageUrl"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.showImageUrl
                            ? _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          flat: "",
                                          "solo-inverted": "",
                                          "persistent-hint": "",
                                          hint:
                                            "Image to display on the featured folder tile",
                                          label: "Image Url",
                                          disabled:
                                            _vm.disableInput ||
                                            !_vm.selectedFolder.userAccess
                                              .canUpdate
                                        },
                                        model: {
                                          value: _vm.editFolder.imageUrl,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.editFolder,
                                              "imageUrl",
                                              $$v
                                            )
                                          },
                                          expression: "editFolder.imageUrl"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-row",
                            {
                              staticClass: "pb-3",
                              attrs: { "no-gutters": "" }
                            },
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-3",
                                      attrs: { small: "", depressed: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.showImageUrl = !_vm.showImageUrl
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.showImageUrl
                                            ? "Select CMS image"
                                            : "Use external image url"
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      flat: "",
                                      "solo-inverted": "",
                                      "persistent-hint": "",
                                      hint: "Display title of the folder",
                                      label: "Title",
                                      disabled:
                                        _vm.disableInput ||
                                        !_vm.selectedFolder.userAccess.canUpdate
                                    },
                                    model: {
                                      value: _vm.editFolder.title,
                                      callback: function($$v) {
                                        _vm.$set(_vm.editFolder, "title", $$v)
                                      },
                                      expression: "editFolder.title"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mt-0 pb-3",
                                    attrs: {
                                      label: "Featured",
                                      color: "primary",
                                      hint:
                                        "Sets the folder show as a featured folder",
                                      "persistent-hint": "",
                                      disabled:
                                        _vm.disableInput ||
                                        !_vm.selectedFolder.userAccess.canUpdate
                                    },
                                    model: {
                                      value: _vm.editFolder.featured,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editFolder,
                                          "featured",
                                          $$v
                                        )
                                      },
                                      expression: "editFolder.featured"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "pb-3",
                              attrs: { "no-gutters": "" }
                            },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      flat: "",
                                      "solo-inverted": "",
                                      "persistent-hint": "",
                                      hint: "Description of the folder",
                                      label: "Description",
                                      rows: "1",
                                      "auto-grow": "",
                                      disabled:
                                        _vm.disableInput ||
                                        !_vm.selectedFolder.userAccess.canUpdate
                                    },
                                    model: {
                                      value: _vm.editFolder.description,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editFolder,
                                          "description",
                                          $$v
                                        )
                                      },
                                      expression: "editFolder.description"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "pb-3",
                              attrs: { "no-gutters": "" }
                            },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.disciplines,
                                      label: "Disciplines",
                                      multiple: "",
                                      chips: "",
                                      filled: "",
                                      hint:
                                        "Default disciplines to be added when adding new documents",
                                      "persistent-hint": "",
                                      dense: "",
                                      disabled:
                                        _vm.disableInput ||
                                        !_vm.selectedFolder.userAccess.canUpdate
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "selection",
                                        fn: function(data) {
                                          return [
                                            _c(
                                              "v-chip",
                                              _vm._b(
                                                {
                                                  key: JSON.stringify(
                                                    data.item
                                                  ),
                                                  staticClass: "ma-2",
                                                  attrs: {
                                                    "input-value":
                                                      data.selected,
                                                    disabled: data.disabled
                                                  },
                                                  on: {
                                                    "click:close": function(
                                                      $event
                                                    ) {
                                                      return data.parent.selectItem(
                                                        data.item
                                                      )
                                                    }
                                                  }
                                                },
                                                "v-chip",
                                                data.attrs,
                                                false
                                              ),
                                              [
                                                _c("v-avatar", {
                                                  staticClass:
                                                    "primary white--text",
                                                  attrs: { left: "" },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      data.item
                                                        .slice(0, 1)
                                                        .toUpperCase()
                                                    )
                                                  }
                                                }),
                                                _vm._v(
                                                  " " + _vm._s(data.item) + " "
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ]),
                                    model: {
                                      value: _vm.editFolder.disciplines,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editFolder,
                                          "disciplines",
                                          $$v
                                        )
                                      },
                                      expression: "editFolder.disciplines"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-combobox", {
                                    attrs: {
                                      label: "Tags",
                                      dense: "",
                                      multiple: "",
                                      flat: "",
                                      filled: "",
                                      disabled:
                                        _vm.disableInput ||
                                        !_vm.selectedFolder.userAccess.canUpdate
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "no-data",
                                        fn: function() {
                                          return [
                                            _c(
                                              "v-list-item",
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c("v-list-item-title", [
                                                      _vm._v(
                                                        ' No results matching " '
                                                      ),
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(_vm.searchTag)
                                                        )
                                                      ]),
                                                      _vm._v('". Press '),
                                                      _c("kbd", [
                                                        _vm._v("enter")
                                                      ]),
                                                      _vm._v(
                                                        " to create a new one "
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        },
                                        proxy: true
                                      },
                                      {
                                        key: "selection",
                                        fn: function(data) {
                                          return [
                                            _c(
                                              "v-chip",
                                              _vm._b(
                                                {
                                                  key: JSON.stringify(
                                                    data.item
                                                  ),
                                                  staticClass: "ma-2",
                                                  attrs: {
                                                    "input-value":
                                                      data.selected,
                                                    disabled: data.disabled
                                                  },
                                                  on: {
                                                    "click:close": function(
                                                      $event
                                                    ) {
                                                      return data.parent.selectItem(
                                                        data.item
                                                      )
                                                    }
                                                  }
                                                },
                                                "v-chip",
                                                data.attrs,
                                                false
                                              ),
                                              [
                                                _c("v-avatar", {
                                                  staticClass:
                                                    "primary white--text",
                                                  attrs: {
                                                    left: "",
                                                    small: ""
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      data.item
                                                        .slice(0, 1)
                                                        .toUpperCase()
                                                    )
                                                  }
                                                }),
                                                _vm._v(
                                                  " " + _vm._s(data.item) + " "
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ]),
                                    model: {
                                      value: _vm.editFolder.tags,
                                      callback: function($$v) {
                                        _vm.$set(_vm.editFolder, "tags", $$v)
                                      },
                                      expression: "editFolder.tags"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _vm.selectedFolder.userAccess.canUpdate
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            depressed: "",
                                            color: "primary",
                                            disabled: _vm.disableInput,
                                            loading: _vm.disableInput
                                          },
                                          on: { click: _vm.saveProperties }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "pr-2" },
                                            [_vm._v("fas fa-save")]
                                          ),
                                          _vm._v("Save ")
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "body-2",
                                  staticStyle: { "text-align": "right" }
                                },
                                [
                                  _vm._v(" Updated by "),
                                  _c("i", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.selectedFolder.modifiedBy ||
                                          "User not found"
                                      )
                                    )
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatDate")(
                                          _vm.selectedFolder.modifiedOn
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "body-2",
                                  staticStyle: { "text-align": "right" }
                                },
                                [
                                  _vm._v(" Created by "),
                                  _c("i", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.selectedFolder.createdBy ||
                                          "User not found"
                                      )
                                    )
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatDate")(
                                          _vm.selectedFolder.createdOn
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { attrs: { value: "folderActivityTab" } },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-timeline",
                            [
                              _c(
                                "v-timeline-item",
                                {
                                  attrs: { small: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "opposite",
                                      fn: function() {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "headline font-weight-bold"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatDate")(
                                                    _vm.editFolder.modifiedOn
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                },
                                [
                                  _c("div", { staticClass: "py-4" }, [
                                    _c(
                                      "h2",
                                      {
                                        staticClass:
                                          "headline font-weight-light mb-4"
                                      },
                                      [_vm._v("Folder Modified")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        "by " +
                                          _vm._s(_vm.editFolder.modifiedBy)
                                      )
                                    ])
                                  ])
                                ]
                              ),
                              _c(
                                "v-timeline-item",
                                {
                                  attrs: { small: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "opposite",
                                      fn: function() {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "headline font-weight-bold"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatDate")(
                                                    _vm.editFolder.createdOn
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                },
                                [
                                  _c("div", { staticClass: "py-4" }, [
                                    _c(
                                      "h2",
                                      {
                                        staticClass:
                                          "headline font-weight-light mb-4"
                                      },
                                      [_vm._v("Folder Created")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        "by " + _vm._s(_vm.editFolder.createdBy)
                                      )
                                    ])
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }