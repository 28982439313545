var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    [
      _c(
        "v-list-item",
        { staticClass: "result-item" },
        [
          _c(
            "v-icon",
            { staticClass: "mr-3", attrs: { color: "primary", large: "" } },
            [_vm._v(_vm._s(_vm._f("documentIconFilter")(_vm.result)))]
          ),
          _c(
            "v-list-item-content",
            [
              _c(
                "v-list-item-title",
                [
                  _c(
                    "a",
                    {
                      staticClass: "result-title",
                      on: {
                        click: function($event) {
                          return _vm.downloadFile(_vm.result)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.result.title))]
                  ),
                  _c(
                    "span",
                    {
                      staticClass:
                        "text--secondary font-italic ml-2 mr-3 subtitle-2"
                    },
                    [_vm._v(_vm._s(_vm.result.filename || _vm.result.fileName))]
                  ),
                  _vm._l(
                    _vm.result.disciplines.concat(_vm.result.tags),
                    function(discipline, i) {
                      return _c(
                        "v-chip",
                        {
                          key: i,
                          staticClass: "ml-1",
                          attrs: { color: "primary", small: "", ripple: false }
                        },
                        [_vm._v(_vm._s(discipline))]
                      )
                    }
                  )
                ],
                2
              ),
              _vm.result.textHighlights
                ? _c(
                    "div",
                    _vm._l(_vm.result.textHighlights, function(highlight, i) {
                      return _c("v-list-item-subtitle", {
                        key: i,
                        domProps: { innerHTML: _vm._s("• " + highlight) }
                      })
                    }),
                    1
                  )
                : _c(
                    "div",
                    [
                      _c("v-list-item-subtitle", {
                        domProps: { innerHTML: _vm._s(_vm.result.description) }
                      })
                    ],
                    1
                  )
            ],
            1
          ),
          _c("v-list-item-action", [
            _c(
              "div",
              [
                _c("span", { staticClass: "resultSize" }, [
                  _vm._v(
                    _vm._s(_vm._f("formatBytes")(_vm.result.fileSizeBytes))
                  )
                ]),
                _c(
                  "v-btn",
                  {
                    staticClass: "pb-1",
                    attrs: { icon: "" },
                    on: {
                      click: function($event) {
                        return _vm.downloadFile(_vm.result)
                      }
                    }
                  },
                  [
                    _c("v-icon", { attrs: { color: "secondary" } }, [
                      _vm._v("fas fa-file-download")
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }