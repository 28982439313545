import { render, staticRenderFns } from "./SideDrawer.vue?vue&type=template&id=38065fd8&scoped=true&"
import script from "./SideDrawer.vue?vue&type=script&lang=ts&"
export * from "./SideDrawer.vue?vue&type=script&lang=ts&"
import style0 from "./SideDrawer.vue?vue&type=style&index=0&id=38065fd8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38065fd8",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VIcon,VImg,VList,VListItem,VListItemIcon,VListItemTitle,VNavigationDrawer,VSubheader})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src567975772/src/search.myaileron.org/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('38065fd8')) {
      api.createRecord('38065fd8', component.options)
    } else {
      api.reload('38065fd8', component.options)
    }
    module.hot.accept("./SideDrawer.vue?vue&type=template&id=38065fd8&scoped=true&", function () {
      api.rerender('38065fd8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/core/SideDrawer.vue"
export default component.exports