import * as tslib_1 from "tslib";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { humanFileSize, mapContentTypeToIcon } from "@/views/admin/DocumentHelpers";
import ContentManagementApiClient from "@/services/api/ContentManagementApiClient";
let FeaturedItem = class FeaturedItem extends Vue {
    constructor() {
        super();
        this.cmsClient = new ContentManagementApiClient(process.env.VUE_APP_API_CMS_BASEURL);
    }
    async downloadFile(result) {
        await this.cmsClient.downloadFile(result.id, result.fileName);
    }
};
FeaturedItem = tslib_1.__decorate([
    Component({
        props: ["result"],
        filters: {
            formatBytes(bytes) {
                return humanFileSize(bytes);
            },
            documentIconFilter(document) {
                return mapContentTypeToIcon(document.contentType, document.fileName);
            }
        }
    })
], FeaturedItem);
export default FeaturedItem;
