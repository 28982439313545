import * as tslib_1 from "tslib";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import moment from "moment";
import { DISCIPLINES } from "@/views/admin/CMSConstants";
const editableProps = ["title", "description", "disciplines", "tags"];
export function sleep(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}
let DocumentProperties = class DocumentProperties extends Vue {
    constructor() {
        super();
        this.editDocument = null;
        this.searchTag = null;
        this.disciplines = DISCIPLINES;
        this.newPermissionType = "Group";
        this.showNewPerimssion = false;
        this.showRemovePermission = false;
        this.newPermissionEmail = "";
        this.newPermissionGroup = "";
        this.emailRule = [
            v => !!v || "E-mail is required",
            v => /.+@.+\..+/.test(v) || "E-mail must be valid"
        ];
        this.selectedPermission = {};
        this.appLocation = window.location.host;
        this.tab = null;
        this.searchTag = null;
        this.newPermissionValid = true;
    }
    documentLink() {
        return `${this.appLocation}/documents/${this.selectedDocument.id}`;
    }
    copyDocumentLink() {
        // https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
        var textArea = document.createElement("textarea");
        // Create a stub textarea to copy the text from
        textArea.style.position = "fixed";
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.width = "2em";
        textArea.style.height = "2em";
        textArea.style.padding = "0";
        textArea.style.border = "none";
        textArea.style.outline = "none";
        textArea.style.boxShadow = "none";
        textArea.style.background = "transparent";
        const link = this.documentLink();
        console.log(link);
        textArea.value = link;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            var successful = document.execCommand("copy");
            var msg = successful ? "successful" : "unsuccessful";
        }
        catch (err) {
            console.error("Unable to copy text");
            console.error(err);
        }
        document.body.removeChild(textArea);
    }
    get versionHeaders() {
        return [
            { text: "Version", value: "versionTag", sortable: false },
            { text: "Created By", value: "createdBy", sortable: false },
            { text: "Created On", value: "createdOn", sortable: false }
        ];
    }
    saveVersionTag(version) {
        this.$emit("updateVersionTag", {
            versionId: version.id,
            versionTag: version.versionTag
        });
    }
    get permissionHeaders() {
        return [
            { text: "Name", value: "roleName", sortable: false },
            { text: "", value: "id", sortable: false, width: 40 }
        ];
    }
    updateIsPublic(newPublicValue) {
        this.$emit("updatedocument", {
            isPublic: newPublicValue
        });
    }
    mounted() {
        this.intializeProperties(this.selectedDocument);
    }
    intializeProperties(documentProps) {
        this.editDocument = documentProps
            ? {
                title: documentProps.title,
                description: documentProps.description,
                disciplines: documentProps.disciplines,
                tags: documentProps.tags,
                isPublic: documentProps.isPublic
            }
            : {};
        if (this.editDocument.isPublic) {
            this.showNewPerimssion = false;
        }
    }
    async saveNewPermission() {
        const formValid = this.$refs.permissionForm.validate();
        if (formValid) {
            this.$emit("addPermission", this.newPermissionType === "Group"
                ? {
                    documentId: this.selectedDocument.id,
                    roleName: this.newPermissionGroup
                }
                : {
                    documentId: this.selectedDocument.id,
                    roleName: this.newPermissionEmail
                });
        }
    }
    openRemovePermission(permission) {
        this.selectedPermission = permission;
        this.showRemovePermission = true;
    }
    async removePermission() {
        this.$emit("removePermission", this.selectedPermission.id);
    }
    get editDocumentModified() {
        let modified = false;
        if (this.selectedDocument) {
            if ((this.selectedDocument.title === null
                ? ""
                : this.selectedDocument.title) !=
                (this.editDocument.title === null ? "" : this.editDocument.title)) {
                modified = true;
            }
            else if ((this.selectedDocument.description === null
                ? ""
                : this.selectedDocument.description) !=
                (this.editDocument.description === null
                    ? ""
                    : this.editDocument.description)) {
                modified = true;
            }
            else if (this.selectedDocument.disciplines != this.editDocument.disciplines) {
                modified = true;
            }
            else if (this.selectedDocument.tags != this.editDocument.tags) {
                modified = true;
            }
        }
        return modified;
    }
    saveProperties() {
        this.$emit("updatedocument", this.editDocument);
    }
};
tslib_1.__decorate([
    Prop()
], DocumentProperties.prototype, "selectedDocument", void 0);
tslib_1.__decorate([
    Prop()
], DocumentProperties.prototype, "permissionGroups", void 0);
tslib_1.__decorate([
    Prop()
], DocumentProperties.prototype, "disableInput", void 0);
DocumentProperties = tslib_1.__decorate([
    Component({
        name: "DocumentProperties",
        components: {},
        watch: {
            selectedDocument: function (newDocument, oldDocument) {
                if (newDocument.id !== oldDocument.id) {
                    this.tab = "documentPropertiesTab";
                    this.showNewPerimssion = false;
                    this.showRemovePermission = false;
                }
                this.intializeProperties(newDocument);
            },
            disableInput: function (newDisableValue) {
                if (!newDisableValue) {
                    this.showNewPerimssion = false;
                    this.showRemovePermission = false;
                    this.newPermissionEmail = "";
                    this.newPermissionGroup = "";
                }
            }
        },
        filters: {
            formatDate(date) {
                return moment(date).fromNow();
            }
        },
        computed: {
            sortedVersions: function () {
                if (this.selectedDocument.versions != null) {
                    return this.selectedDocument.versions.sort(function (v1, v2) {
                        return (new Date(v2.createdOn).getTime() - new Date(v1.createdOn).getTime());
                    });
                }
                else {
                    return [];
                }
            },
            groupPermissions: function () {
                if (this.selectedDocument != null) {
                    return this.selectedDocument.permissions.filter(function (permission) {
                        return (!permission.isLocked && !/.+@.+\..+/.test(permission.roleName));
                    });
                }
                else {
                    return [];
                }
            },
            userPermissions: function () {
                if (this.selectedDocument != null) {
                    return this.selectedDocument.permissions.filter(function (permission) {
                        return (!permission.isLocked && /.+@.+\..+/.test(permission.roleName));
                    });
                }
                else {
                    return [];
                }
            }
        }
    })
], DocumentProperties);
export default DocumentProperties;
