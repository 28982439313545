import { render, staticRenderFns } from "./MaterialLayout.vue?vue&type=template&id=5a638330&"
import script from "./MaterialLayout.vue?vue&type=script&lang=ts&"
export * from "./MaterialLayout.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VContent } from 'vuetify/lib/components/VContent';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VContent,VFadeTransition,VFooter,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src567975772/src/search.myaileron.org/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5a638330')) {
      api.createRecord('5a638330', component.options)
    } else {
      api.reload('5a638330', component.options)
    }
    module.hot.accept("./MaterialLayout.vue?vue&type=template&id=5a638330&", function () {
      api.rerender('5a638330', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layouts/MaterialLayout.vue"
export default component.exports