import * as tslib_1 from "tslib";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Mutation } from "vuex-class";
import AuthServiceFactory from "@/services/authentication/AuthServiceFactory";
let ToolbarView = class ToolbarView extends Vue {
    constructor() {
        super();
        this.authService = AuthServiceFactory.GetAuthService();
        this.userInitials = "";
        this.user = null;
        this.isLoggedIn = false;
    }
    get dashboardUrl() {
        return process.env.VUE_APP_DASHBOARD_URL;
    }
    async mounted() {
        this.isLoggedIn = await this.authService.isLoggedIn();
        if (this.isLoggedIn === true) {
            this.user = await this.authService.getUser();
            this.userInitials = this.user.profile.given_name
                .substring(0, 1)
                .concat(this.user.profile.family_name.substring(0, 1));
        }
        else {
            this.setDrawer(false);
        }
    }
    async loginClicked() {
        await this.authService.login();
    }
    signOut(event) {
        this.authService.logout();
        this.$router.push("/");
    }
    editProfile(event) {
        this.$router.push("/user/profile");
    }
};
tslib_1.__decorate([
    Mutation("setDrawer")
], ToolbarView.prototype, "setDrawer", void 0);
tslib_1.__decorate([
    Mutation("toggleDrawer")
], ToolbarView.prototype, "toggleDrawer", void 0);
tslib_1.__decorate([
    Mutation("toggleMini")
], ToolbarView.prototype, "toggleMini", void 0);
tslib_1.__decorate([
    Mutation("setMini")
], ToolbarView.prototype, "setMini", void 0);
tslib_1.__decorate([
    Mutation("SET_DRAWER")
], ToolbarView.prototype, "SET_DRAWER", void 0);
ToolbarView = tslib_1.__decorate([
    Component({
        name: "ToolbarView",
        components: {}
    })
], ToolbarView);
export default ToolbarView;
