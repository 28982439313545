import * as tslib_1 from "tslib";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import FeaturedItem from "@/components/search/FeaturedItem.vue";
import { Action, Getter } from "vuex-class";
let Search = class Search extends Vue {
    mounted() {
        this.getFeaturedFolders();
    }
    async getFeaturedFolders() {
        await this.retrieveFeaturedFolders();
    }
    async featuredItemClick(folderId) {
        this.$router.push({
            path: "/search/results",
            query: { folder: folderId }
        });
    }
    searchTermChanged(event) {
        // This will be triggered every time a new character is input
    }
    searchClicked() {
        if (this.searchTerm) {
            this.$router.push({
                path: `/search/results?`,
                query: { q: this.searchTerm }
            });
            this.searchTerm = "";
        }
    }
};
tslib_1.__decorate([
    Action("retrieveFeaturedFolders")
], Search.prototype, "retrieveFeaturedFolders", void 0);
tslib_1.__decorate([
    Action("getFeaturedContents")
], Search.prototype, "getFeaturedContents", void 0);
tslib_1.__decorate([
    Getter("activeFeaturedFolders")
], Search.prototype, "activeFeaturedFolders", void 0);
Search = tslib_1.__decorate([
    Component({
        name: "Search",
        components: { FeaturedItem },
        data: () => ({
            searchTerm: ""
        })
    })
], Search);
export default Search;
