var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-hover", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var hover = ref.hover
              return [
                _c(
                  "v-card",
                  {
                    attrs: {
                      height: "400px",
                      elevation: hover ? 24 : 6,
                      color: hover ? "grey lighten-3" : "white"
                    }
                  },
                  [
                    !_vm.item.imageUrl
                      ? _c(
                          "v-icon",
                          {
                            staticClass: "featured-folder-icon",
                            attrs: { color: "primary" }
                          },
                          [_vm._v("mdi-folder")]
                        )
                      : _vm._e(),
                    _vm.item.imageUrl
                      ? _c("v-img", {
                          staticClass: "align-end",
                          attrs: { height: "215px", src: _vm.item.imageUrl }
                        })
                      : _vm._e(),
                    _c("v-card-title", [_vm._v(_vm._s(_vm.item.title))]),
                    _c("v-card-text", { staticClass: "subtitle-1" }, [
                      _vm._v(_vm._s(_vm.item.description))
                    ])
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }