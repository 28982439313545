var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("v-text-field", {
        staticClass: "pb-5 cms-search",
        attrs: {
          "hide-details": "",
          outlined: "",
          "background-color": "white",
          solo: "",
          flat: "",
          autocomplete: "off",
          "append-icon": "fas fa-search fa-flip-horizontal",
          label: "Search",
          type: "text"
        },
        on: {
          input: _vm.searchTermChanged,
          keydown: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.searchClicked($event)
          }
        },
        model: {
          value: _vm.searchTerm,
          callback: function($$v) {
            _vm.searchTerm = $$v
          },
          expression: "searchTerm"
        }
      }),
      !_vm.activeSearchResults && _vm.isLoggedIn
        ? _c("i", [
            _vm._v('No files found for "' + _vm._s(this.$route.query.q) + '".')
          ])
        : !_vm.activeSearchResults && !_vm.isLoggedIn
        ? _c("i", [
            _vm._v(
              'No files found for "' +
                _vm._s(this.$route.query.q) +
                '", try logging in for more results'
            )
          ])
        : _vm._e(),
      _vm.activeSearchResults
        ? _c(
            "v-list",
            { attrs: { "two-line": "" } },
            [
              _c(
                "v-list-item-group",
                { attrs: { color: "primary" } },
                _vm._l(_vm.activeSearchResults, function(result, i) {
                  return _c("Result", {
                    key: i,
                    staticClass: "search-result",
                    attrs: { result: result }
                  })
                }),
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }