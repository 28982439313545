import * as tslib_1 from "tslib";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import Result from "@/components/search/Result.vue";
import AuthServiceFactory from "@/services/authentication/AuthServiceFactory";
let SearchResults = class SearchResults extends Vue {
    constructor() {
        super(...arguments);
        this.isLoggedIn = false;
    }
    async mounted() {
        this.$data.searchTerm = this.$route.query.q;
        const authService = AuthServiceFactory.GetAuthService();
        this.isLoggedIn = await authService.isLoggedIn();
        this.getResults();
    }
    async getResults() {
        if (this.$route.query.folder) {
            await this.getFeaturedContents(this.$route.query.folder);
        }
        else {
            await this.searchDocuments(this.$route.query.q);
        }
    }
    searchTermChanged(newSearchTerm) {
        this.$data.searchTerm = newSearchTerm;
    }
    searchClicked() {
        if (this.$data.searchTerm) {
            this.$router.push({ path: `/search/results?q=${this.$data.searchTerm}` });
        }
    }
};
tslib_1.__decorate([
    Action("searchDocuments")
], SearchResults.prototype, "searchDocuments", void 0);
tslib_1.__decorate([
    Getter("activeSearchResults")
], SearchResults.prototype, "activeSearchResults", void 0);
tslib_1.__decorate([
    Action("getFeaturedContents")
], SearchResults.prototype, "getFeaturedContents", void 0);
SearchResults = tslib_1.__decorate([
    Component({
        name: "SearchResults",
        components: { Result },
        data: () => ({
            searchTerm: ""
        }),
        watch: {
            $route(to, from) {
                this.getResults();
            }
        }
    })
], SearchResults);
export default SearchResults;
