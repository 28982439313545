import * as tslib_1 from "tslib";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import moment from "moment-timezone";
import ContentManagementApiClient from "../../services/api/ContentManagementApiClient";
import FolderProperties from "@/components/FolderProperties.vue";
import DocumentProperties from "@/components/DocumentProperties.vue";
import { humanFileSize, mapContentTypeToIcon } from "./DocumentHelpers";
import { DISCIPLINES } from "./CMSConstants";
let FileManagerView = class FileManagerView extends Vue {
    constructor() {
        super();
        this.activeFolders = [];
        this.showDeleteDocument = false;
        this.documentToDelete = {};
        this.deletingDocument = false;
        this.isDragging = false;
        this.showMessage = false;
        this.message = {};
        this.disciplines = DISCIPLINES;
        this.folders = new Array();
        this.tree = new Array();
        this.files = new Array();
        this.imageFiles = new Array();
        this.showPropertiesView = false;
        this.selectedFolder = null;
        this.activeFolderId = "";
        this.activeFolderName = "";
        this.activeFolder = null;
        this.selectedType = "";
        this.selectedRows = new Array();
        this.selectedDocument = null;
        this.editPropertiesTitle = "";
        this.savingProperties = false;
        this.uploading = false;
        this.deletingDocument = false;
        this.showAddFolderDialog = false;
        this.newFolderName = "";
        this.permissionGroups = [];
        this.tab = null;
        this.searchTag = null;
    }
    async mounted() {
        this.cmsClient = new ContentManagementApiClient(process.env.VUE_APP_API_CMS_BASEURL);
        this.loadNavigation().then(() => {
            this.activeFolder = this.rootFolder;
            this.activeFolderId = this.rootFolder.id;
            this.activeFolderName = this.rootFolder.name;
            this.loadImageOptions();
        });
        this.loadPermissionGroups();
    }
    async loadImageOptions() {
        const imageFolder = this.folders.find(f => f.name === "Images");
        if (imageFolder != null) {
            this.imageFiles = await this.cmsClient.getFolderDocuments(imageFolder.id, true);
        }
    }
    async loadPermissionGroups() {
        try {
            const groups = await this.cmsClient.getPermissionGroups();
            const groupsByName = groups.sort((a, b) => {
                return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
            });
            this.permissionGroups = groupsByName;
        }
        catch (ex) {
            this.showErrorMessage(ex);
            this.permissionGroups = [];
        }
    }
    openAddFolder(folder) {
        if (folder != null) {
            this.activeFolders = [folder.id];
        }
        else {
            this.activeFolders = [this.rootFolder.id];
        }
        this.showAddFolderDialog = true;
    }
    async addFolder() {
        try {
            const parentFolderId = this.activeFolderId !== "" ? this.activeFolderId : this.rootFolder.id;
            await this.cmsClient.addFolder({
                name: this.newFolderName,
                parentFolderId: parentFolderId
            });
            await this.loadNavigation();
        }
        catch (ex) {
            this.showErrorMessage(ex.message);
        }
        finally {
            this.showAddFolderDialog = false;
            this.newFolderName = "";
        }
    }
    onDragover() {
        clearTimeout(this.dragTimeout);
        this.isDragging = true;
    }
    onDragleave() {
        clearTimeout(this.dragTimeout);
        this.dragTimeout = setTimeout(() => {
            this.isDragging = false;
        }, 200);
    }
    onFilesDropped(e) {
        this.isDragging = false;
        this.uploadFileList(e.dataTransfer.files);
    }
    onFilesSelected(fileInputEventData) {
        this.uploadFileList(fileInputEventData.target.files);
    }
    async uploadFileList(fileList) {
        if (this.activeFolderId !== "") {
            this.uploading = true;
            for (const file of fileList) {
                try {
                    await this.cmsClient.uploadDocument(this.activeFolderId, file);
                }
                catch (ex) {
                    this.showErrorMessage(ex.message);
                }
            }
            try {
                await this.loadFolderDocuments(this.activeFolderId);
            }
            catch (ex) {
                this.showErrorMessage(ex.message);
            }
            finally {
                this.uploading = false;
            }
        }
    }
    async updateFolder(folderProperties) {
        try {
            this.savingProperties = true;
            const updatedFolder = await this.cmsClient.updateFolderProperties(this.selectedFolder.id, folderProperties);
            this.selectedFolder = updatedFolder;
        }
        catch (ex) {
            this.showErrorMessage(ex);
        }
        finally {
            this.savingProperties = false;
        }
    }
    async updateDocument(documentProperties) {
        try {
            this.savingProperties = true;
            const updatedDocument = await this.cmsClient.updateDocumentProperties(this.selectedDocument.id, documentProperties);
            const oldFileIndex = this.files.findIndex(f => f.id === this.selectedDocument.id);
            this.files[oldFileIndex] = updatedDocument;
            this.selectedDocument = updatedDocument;
        }
        catch (ex) {
            this.showErrorMessage(ex.message);
        }
        finally {
            this.savingProperties = false;
        }
    }
    async updateVersionTag(versionUpdate) {
        try {
            this.savingProperties = true;
            const updatedVersion = await this.cmsClient.updateDocumentVersionProperties(versionUpdate.versionId, {
                versionTag: versionUpdate.versionTag
            });
        }
        catch (ex) {
            this.showErrorMessage(ex.message);
        }
        finally {
            this.savingProperties = false;
        }
    }
    openDocumentDelete(document) {
        this.documentToDelete = document;
        this.showDeleteDocument = true;
    }
    async deleteDocument() {
        try {
            this.deletingDocument = true;
            await this.cmsClient.removeDocument(this.documentToDelete.id);
            await this.loadFolderDocuments(this.activeFolderId);
        }
        catch (ex) {
            this.showErrorMessage(ex.message);
        }
        finally {
            this.deletingDocument = false;
            this.showDeleteDocument = false;
        }
    }
    async addDocumentPermission(documentPermissionProps) {
        try {
            this.savingProperties = true;
            const newPermission = await this.cmsClient.addDocumentViewPermission(documentPermissionProps.documentId, documentPermissionProps.roleName);
            this.selectedDocument.permissions.unshift(newPermission);
        }
        catch (ex) {
            this.showErrorMessage(ex.message);
        }
        finally {
            this.savingProperties = false;
        }
    }
    async removeDocumentPermission(permissionId) {
        try {
            this.savingProperties = true;
            await this.cmsClient.removePermission(permissionId);
            this.selectedDocument.permissions = await this.cmsClient.getDocumentPermissions(this.selectedDocument.id);
        }
        catch (ex) {
            this.showErrorMessage(ex.message);
        }
        finally {
            this.savingProperties = false;
        }
    }
    async downloadDocument(document) {
        await this.cmsClient.downloadFile(document.id, document.fileName);
    }
    get documentHeaders() {
        let header = [
            { text: "", value: "contentType", sortable: false },
            { text: "Filename", value: "name", sortable: true }
        ];
        if (!this.$store.state.drawer) {
            // header.push({ text: "Title", value: "title", sortable: false });
            // header.push({ text: "Created By", value: "createdBy", sortable: false });
            // header.push({ text: "Created On", value: "createdOn", sortable: false });
            header.push({
                text: "Modified By",
                value: "lastModifiedBy",
                sortable: false
            });
            header.push({
                text: "Modified On",
                value: "lastModifiedOn",
                sortable: false
            });
        }
        header.push({ text: "Size", value: "size", sortable: false });
        header.push({ text: "", value: "actions", width: 120, sortable: false });
        return header;
    }
    documentSelected(doc) {
        this.selectedRows.push(doc);
    }
    documentInfoClicked(doc) {
        this.showPropertiesView = true;
    }
    async activeFolderChanged(folderIds) {
        if (folderIds.length === 0) {
            this.activeFolder = this.rootFolder;
            this.activeFolderId = this.rootFolder.id;
            this.activeFolderName = this.rootFolder.name;
        }
        else {
            this.activeFolderId = folderIds[0];
            const previousFolderId = this.activeFolders[0];
            this.activeFolder = this.findNavigationFolder(this.folders, this.activeFolderId);
            this.activeFolderName = this.activeFolder.name;
            if (previousFolderId !== this.activeFolderId) {
                this.closePropertiesView();
            }
            this.selectedRows = new Array();
            await this.loadFolderDocuments(this.activeFolderId);
        }
    }
    findNavigationFolder(folders, folderId) {
        const foundFolder = folders.find(folder => folder.id === folderId);
        if (foundFolder == null) {
            for (const folder of folders) {
                const foundSubFolder = this.findNavigationFolder(folder.children, folderId);
                if (foundSubFolder != null) {
                    return foundSubFolder;
                }
            }
        }
        else {
            return foundFolder;
        }
    }
    async loadFolderDocuments(folderId) {
        try {
            this.files = await this.cmsClient.getFolderDocuments(folderId);
        }
        catch (ex) {
            this.showErrorMessage(ex.message);
        }
    }
    showErrorMessage(messageText) {
        this.message = {
            text: messageText,
            color: "error"
        };
        this.showMessage = true;
    }
    async loadNavigation() {
        try {
            const rootFolder = await this.cmsClient.getFolderNavigation();
            this.folders = rootFolder.children;
            this.rootFolder = rootFolder;
        }
        catch (ex) {
            this.showErrorMessage(ex.message);
        }
    }
    async showFolderProperties(folder) {
        try {
            // Loading images here is overkill, but is needed if the user is wanting to use a newly uploaded image as the folder image
            this.loadImageOptions();
            this.selectedType = "folder";
            this.selectedFolder = await this.cmsClient.getFolderProperties(folder.id);
            this.showPropertiesView = true;
            this.editPropertiesTitle = this.selectedFolder.name;
            this.activeFolders = [folder.id];
        }
        catch (ex) {
            this.showErrorMessage(ex.message);
        }
    }
    async showDocumentProperties(document) {
        this.selectedType = "document";
        const doucmentIndex = this.files.findIndex(f => f.id === document.id);
        this.selectedDocument = this.files[doucmentIndex];
        this.showPropertiesView = true;
        this.editPropertiesTitle = this.selectedDocument.fileName;
    }
    closePropertiesView() {
        this.showPropertiesView = false;
        this.selectedType = "";
        this.selectedFolder = null;
        this.selectedDocument = null;
    }
};
FileManagerView = tslib_1.__decorate([
    Component({
        name: "FileManager",
        components: { FolderProperties, DocumentProperties },
        filters: {
            formatDate(date) {
                return moment(date).fromNow();
            },
            formatBytes(bytes) {
                return humanFileSize(bytes);
            },
            documentIconFilter(document) {
                return mapContentTypeToIcon(document.contentType, document.fileName);
            }
        }
    })
], FileManagerView);
export default FileManagerView;
