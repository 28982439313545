var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("v-text-field", {
        staticClass: "pb-5",
        attrs: {
          "hide-details": "",
          outlined: "",
          "background-color": "white",
          solo: "",
          flat: "",
          autocomplete: "off",
          "append-icon": "fas fa-search fa-flip-horizontal",
          label: "Search for other documents",
          type: "text"
        },
        on: {
          input: _vm.searchTermChanged,
          keydown: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.searchClicked($event)
          }
        },
        model: {
          value: _vm.searchTerm,
          callback: function($$v) {
            _vm.searchTerm = $$v
          },
          expression: "searchTerm"
        }
      }),
      _vm.loadingDocument
        ? _c("i", [_vm._v("Retrieving document")])
        : _vm.documentFound && _vm.documentAvaliable
        ? _c(
            "v-list",
            { attrs: { "two-line": "" } },
            [
              _c(
                "v-list-item-group",
                { attrs: { color: "primary" } },
                [
                  _c(
                    "v-list",
                    { staticClass: "search-result" },
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-3",
                              attrs: { color: "primary", large: "" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("documentIconFilter")(_vm.cmsDocument)
                                )
                              )
                            ]
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "result-title",
                                      on: {
                                        click: function($event) {
                                          return _vm.downloadFile(
                                            _vm.cmsDocument
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.cmsDocument.title))]
                                  ),
                                  _vm._l(
                                    _vm.cmsDocument.disciplines.concat(
                                      _vm.cmsDocument.tags
                                    ),
                                    function(discipline, i) {
                                      return _c(
                                        "v-chip",
                                        {
                                          key: i,
                                          staticClass: "ml-1",
                                          attrs: {
                                            color: "primary",
                                            small: "",
                                            ripple: false
                                          }
                                        },
                                        [_vm._v(_vm._s(discipline))]
                                      )
                                    }
                                  )
                                ],
                                2
                              ),
                              _c("v-list-item-subtitle", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.cmsDocument.description)
                                }
                              })
                            ],
                            1
                          ),
                          _c("v-list-item-action", [
                            _c(
                              "div",
                              [
                                _c("span", { staticClass: "resultSize" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatBytes")(
                                        _vm.cmsDocument.fileSizeBytes
                                      )
                                    )
                                  )
                                ]),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass: "pb-1",
                                                  attrs: { icon: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.downloadFile(
                                                        _vm.cmsDocument
                                                      )
                                                    }
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color: "secondary"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "fas fa-file-download"
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  },
                                  [_c("span", [_vm._v("Download")])]
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm.documentFound && !_vm.documentAvaliable && !_vm.isLoggedIn
        ? _c("i", [
            _vm._v("Document is unavaliable, please log in to access it")
          ])
        : _vm.documentFound && !_vm.documentAvaliable && _vm.isLoggedIn
        ? _c("i", [
            _vm._v(
              " Oops, looks like you don't have access to this document. Please contact customer support at "
            ),
            _c("a", { attrs: { href: "mailto:customerservice@aileron.org" } }, [
              _vm._v("customerservice@aileron.org")
            ]),
            _vm._v(". ")
          ])
        : !_vm.documentFound && !_vm.documentAvaliable
        ? _c("i", [
            _vm._v(
              " Hmm, we can't find the document you are looking for. Please try your link again or contact customer support at "
            ),
            _c("a", { attrs: { href: "mailto:customerservice@aileron.org" } }, [
              _vm._v("customerservice@aileron.org")
            ]),
            _vm._v(". ")
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }