var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      attrs: {
        dark: "",
        id: "core-toolbar",
        app: "",
        flat: "",
        "clipped-left": "",
        color: "primary"
      }
    },
    [
      _c("v-app-bar-nav-icon", { on: { click: _vm.toggleDrawer } }),
      _c(
        "v-btn",
        {
          staticClass: "ml-5",
          attrs: {
            light: "",
            color: "white",
            dense: "",
            depressed: "",
            link: "",
            href: _vm.dashboardUrl,
            target: "_blank"
          }
        },
        [_vm._v("Member Dashboard")]
      ),
      _c("v-spacer"),
      _vm.user != null
        ? _c(
            "v-menu",
            {
              attrs: { left: "", bottom: "", "offset-y": "", "offset-x": "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g({ attrs: { icon: "" } }, on),
                          [
                            _c(
                              "v-avatar",
                              { attrs: { size: "48" } },
                              [
                                _c("v-label", { attrs: { dark: "" } }, [
                                  _vm._v(_vm._s(_vm.userInitials))
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                3386897699
              )
            },
            [
              _c(
                "v-list",
                { attrs: { dark: "", dense: "", nav: "", color: "menu" } },
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-btn",
                        { attrs: { text: "" }, on: { click: _vm.signOut } },
                        [
                          _c("v-icon", { attrs: { small: "", left: "" } }, [
                            _vm._v("mdi-logout-variant")
                          ]),
                          _vm._v("Sign Out ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-btn",
            {
              attrs: {
                "background-color": "white",
                dense: "",
                depressed: "",
                light: "",
                color: "white"
              },
              on: { click: _vm.loginClicked }
            },
            [_vm._v("Login")]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }