var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    {
      attrs: { "no-gutters": "", "fill-height": "" },
      on: {
        dragover: function($event) {
          $event.preventDefault()
          return _vm.onDragover($event)
        },
        drop: function($event) {
          $event.preventDefault()
          return _vm.onFilesDropped($event)
        },
        dragleave: function($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.onDragleave($event)
        }
      }
    },
    [
      _vm.$vuetify.breakpoint.smAndUp
        ? _c(
            "v-col",
            { attrs: { sm: "auto" } },
            [
              _vm.isDragging &&
              _vm.activeFolderId &&
              _vm.activeFolder.userAccess.canAddDocument
                ? _c("v-overlay", { attrs: { absolute: "" } }, [
                    _c("h2", { staticStyle: {} }, [
                      _vm._v(
                        "Drop Files Here to upload to '" +
                          _vm._s(_vm.activeFolderName) +
                          "'"
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.uploading
                ? _c(
                    "v-overlay",
                    { attrs: { absolute: "" } },
                    [
                      _c("v-progress-circular", {
                        staticStyle: { display: "inline-block" },
                        attrs: { indeterminate: "", color: "primary" }
                      }),
                      _c("h2", { staticClass: "overlay-header" }, [
                        _vm._v(
                          "Uploading Documents to '" +
                            _vm._s(_vm.activeFolderName) +
                            "'"
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-snackbar",
                {
                  attrs: {
                    top: "",
                    right: "",
                    color: _vm.message.color,
                    "multi-line": "",
                    timeout: 0
                  },
                  model: {
                    value: _vm.showMessage,
                    callback: function($$v) {
                      _vm.showMessage = $$v
                    },
                    expression: "showMessage"
                  }
                },
                [
                  _vm._v(" " + _vm._s(_vm.message.text) + " "),
                  _c(
                    "v-btn",
                    {
                      attrs: { dark: "", text: "" },
                      on: {
                        click: function($event) {
                          _vm.showMessage = false
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                ],
                1
              ),
              _c(
                "v-toolbar",
                {
                  staticClass: "folder-toolbar",
                  attrs: { flat: "", color: "menu", dark: "" }
                },
                [
                  _c(
                    "v-toolbar-title",
                    [_c("v-label", [_vm._v("Folders")])],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-toolbar-items",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            small: "",
                            icon: "",
                            disabled:
                              _vm.activeFolder &&
                              !_vm.activeFolder.userAccess.canAddSubFolder
                          },
                          on: { click: _vm.openAddFolder }
                        },
                        [
                          _c("v-icon", { attrs: { color: "white" } }, [
                            _vm._v("fas fa-folder-plus")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-dialog",
                        {
                          attrs: { "max-width": "700" },
                          model: {
                            value: _vm.showAddFolderDialog,
                            callback: function($$v) {
                              _vm.showAddFolderDialog = $$v
                            },
                            expression: "showAddFolderDialog"
                          }
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c("v-card-title", { staticClass: "headline" }, [
                                _vm._v(
                                  "Add Folder to '" +
                                    _vm._s(_vm.activeFolderName) +
                                    "'"
                                )
                              ]),
                              _c(
                                "v-container",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Folder Name",
                                      placeholder: "",
                                      solo: "",
                                      dense: "",
                                      counter: "30"
                                    },
                                    model: {
                                      value: _vm.newFolderName,
                                      callback: function($$v) {
                                        _vm.newFolderName = $$v
                                      },
                                      expression: "newFolderName"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        depressed: "",
                                        disabled: _vm.newFolderName.length > 30
                                      },
                                      on: { click: _vm.addFolder }
                                    },
                                    [_vm._v("Add")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "", depressed: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.showAddFolderDialog = false
                                          _vm.newFolderName = ""
                                        }
                                      }
                                    },
                                    [_vm._v("Cancel")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card",
                {
                  staticClass: "folders-tree-card",
                  attrs: { flat: "", tile: "", width: "400", "fill-height": "" }
                },
                [
                  _c("v-treeview", {
                    attrs: {
                      items: _vm.folders,
                      activatable: "",
                      hoverable: "",
                      dense: "",
                      active: _vm.activeFolders,
                      "fill-height": "",
                      "item-key": "id"
                    },
                    on: { "update:active": _vm.activeFolderChanged },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "prepend",
                          fn: function(ref) {
                            var item = ref.item
                            var open = ref.open
                            return [
                              _c("v-icon", { attrs: { color: "#E6CE6D" } }, [
                                _vm._v(
                                  _vm._s(
                                    open
                                      ? "fas fa-folder-open"
                                      : "fas fa-folder"
                                  )
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "label",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("v-hover", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        return [
                                          _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "pr-3 folder-tree-label"
                                              },
                                              [_vm._v(_vm._s(item.name))]
                                            )
                                          ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ]
                          }
                        },
                        {
                          key: "append",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "div",
                                { staticClass: "folder-actions" },
                                [
                                  item.userAccess.canAddSubFolder
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-1",
                                          attrs: {
                                            small: "",
                                            color: "primary",
                                            icon: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.openAddFolder(item)
                                            }
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("fas fa-folder-plus")
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !item.applicationFolder
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: { small: "", icon: "" },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.showFolderProperties(
                                                item
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "pa-0",
                                              attrs: {
                                                small: "",
                                                color: "primary"
                                              }
                                            },
                                            [_vm._v("fas fa-edit")]
                                          )
                                        ],
                                        1
                                      )
                                    : item.applicationFolder
                                    ? _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("fas fa-lock")
                                      ])
                                    : _c("div", {
                                        staticClass: "folder-icon-placeholder"
                                      })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2457738813
                    ),
                    model: {
                      value: _vm.tree,
                      callback: function($$v) {
                        _vm.tree = $$v
                      },
                      expression: "tree"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("v-divider", { attrs: { vertical: "", "fill-height": "" } }),
      _c(
        "v-col",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "folder-toolbar",
              attrs: { flat: "", color: "menu", dark: "" }
            },
            [
              _c("v-toolbar-title", [_c("v-label", [_vm._v("Documents")])], 1),
              _c("v-spacer"),
              _c(
                "v-toolbar-items",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        small: "",
                        icon: "",
                        disabled:
                          _vm.activeFolderId === "" ||
                          !_vm.activeFolder.userAccess.canAddDocument
                      },
                      on: {
                        click: function($event) {
                          return _vm.$refs.fileUpload.click()
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { color: "white" } }, [
                        _vm._v("fas fa-file-upload")
                      ]),
                      _c("input", {
                        ref: "fileUpload",
                        staticStyle: { display: "none" },
                        attrs: { type: "file", multiple: "" },
                        on: {
                          change: function($event) {
                            return _vm.onFilesSelected($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card",
            {
              staticClass: "d-flex flex-column folders-tree-card",
              attrs: { flat: "", tile: "" }
            },
            [
              _c("v-data-table", {
                staticClass: "elevation-0",
                attrs: {
                  "calculate-widths": "",
                  headers: _vm.documentHeaders,
                  items: _vm.files,
                  "item-key": "name",
                  "hide-default-footer": _vm.files.length <= 10,
                  "fixed-header": "",
                  value: _vm.selectedRows
                },
                on: { "click:row": _vm.documentSelected },
                scopedSlots: _vm._u([
                  {
                    key: "item.title",
                    fn: function(ref) {
                      var item = ref.item
                      return [_c("span", [_vm._v(_vm._s(item.title))])]
                    }
                  },
                  {
                    key: "item.contentType",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("v-icon", { attrs: { color: "primary" } }, [
                          _vm._v(_vm._s(_vm._f("documentIconFilter")(item)))
                        ])
                      ]
                    }
                  },
                  {
                    key: "item.name",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("div", { staticStyle: { "min-width": "225px" } }, [
                          _c("div", [_vm._v(_vm._s(item.fileName))])
                        ])
                      ]
                    }
                  },
                  {
                    key: "item.createdOn",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("div", { staticStyle: { "min-width": "100px" } }, [
                          _vm._v(_vm._s(_vm._f("formatDate")(item.createdOn)))
                        ])
                      ]
                    }
                  },
                  {
                    key: "item.createdBy",
                    fn: function(ref) {
                      var item = ref.item
                      return [_c("div", [_vm._v(_vm._s(item.createdBy))])]
                    }
                  },
                  {
                    key: "item.lastModifiedOn",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("div", { staticStyle: { "min-width": "100px" } }, [
                          _vm._v(_vm._s(_vm._f("formatDate")(item.modifiedOn)))
                        ])
                      ]
                    }
                  },
                  {
                    key: "item.lastModifiedBy",
                    fn: function(ref) {
                      var item = ref.item
                      return [_c("div", [_vm._v(_vm._s(item.modifiedBy))])]
                    }
                  },
                  {
                    key: "item.size",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("div", { staticStyle: { "min-width": "75px" } }, [
                          _vm._v(
                            _vm._s(_vm._f("formatBytes")(item.fileSizeBytes))
                          )
                        ])
                      ]
                    }
                  },
                  {
                    key: "item.actions",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: { small: "", icon: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.downloadDocument(
                                                  item
                                                )
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "primary"
                                              }
                                            },
                                            [_vm._v("fas fa-file-download")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_c("span", [_vm._v("Download")])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: { small: "", icon: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.showDocumentProperties(
                                                  item
                                                )
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "primary"
                                              }
                                            },
                                            [_vm._v("fas fa-edit")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_c("span", [_vm._v("Properties")])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      item.userAccess.canDelete
                                        ? _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass: "ml-1",
                                                attrs: { small: "", icon: "" },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.openDocumentDelete(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "primary" } },
                                                [_vm._v("mdi-delete")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_c("span", [_vm._v("Delete")])]
                        )
                      ]
                    }
                  },
                  {
                    key: "no-data",
                    fn: function() {
                      return [
                        _c("v-label", [_vm._v("No documents in this folder")])
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "500" },
                  model: {
                    value: _vm.showDeleteDocument,
                    callback: function($$v) {
                      _vm.showDeleteDocument = $$v
                    },
                    expression: "showDeleteDocument"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-overlay",
                        {
                          attrs: { absolute: "", value: _vm.deletingDocument }
                        },
                        [
                          _c("v-progress-circular", {
                            staticStyle: { display: "inline-block" },
                            attrs: { indeterminate: "", color: "primary" }
                          }),
                          _c(
                            "h2",
                            {
                              staticStyle: {
                                display: "inline-block",
                                "margin-left": "10px",
                                "verticle-align": "middle"
                              }
                            },
                            [
                              _vm._v(
                                "Deleting " +
                                  _vm._s(_vm.documentToDelete.fileName)
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("v-card-title", { staticClass: "headline" }, [
                        _vm._v("Delete document")
                      ]),
                      _c("v-container", [
                        _c("span", { staticClass: "body-2" }, [
                          _vm._v(
                            "Are you sure you want to delete " +
                              _vm._s(_vm.documentToDelete.fileName) +
                              "?"
                          )
                        ])
                      ]),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", depressed: "" },
                              on: { click: _vm.deleteDocument }
                            },
                            [_vm._v("Yes")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "", depressed: "" },
                              on: {
                                click: function($event) {
                                  _vm.showDeleteDocument = false
                                }
                              }
                            },
                            [_vm._v("Cancel")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider", { attrs: { vertical: "" } }),
      _c(
        "v-navigation-drawer",
        {
          attrs: { right: "", temporary: "", absolute: "", width: "700" },
          model: {
            value: _vm.showPropertiesView,
            callback: function($$v) {
              _vm.showPropertiesView = $$v
            },
            expression: "showPropertiesView"
          }
        },
        [
          _vm.showPropertiesView
            ? _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-toolbar",
                        {
                          staticClass: "folder-toolbar",
                          attrs: { flat: "", color: "menu", dark: "" }
                        },
                        [
                          _c(
                            "v-toolbar-title",
                            [
                              _c("v-label", [
                                _vm._v(_vm._s(_vm.editPropertiesTitle))
                              ])
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-toolbar-items",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { small: "", icon: "" },
                                  on: { click: _vm.closePropertiesView }
                                },
                                [_c("v-icon", [_vm._v("fas fa-times")])],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.selectedType === "folder"
                        ? _c("folder-properties", {
                            attrs: {
                              disableInput: _vm.savingProperties,
                              selectedFolder: _vm.selectedFolder,
                              imageOptions: _vm.imageFiles
                            },
                            on: { updatefolder: _vm.updateFolder }
                          })
                        : _vm._e(),
                      _vm.selectedType === "document"
                        ? _c("document-properties", {
                            attrs: {
                              disableInput: _vm.savingProperties,
                              selectedDocument: _vm.selectedDocument,
                              permissionGroups: _vm.permissionGroups
                            },
                            on: {
                              updatedocument: _vm.updateDocument,
                              addPermission: _vm.addDocumentPermission,
                              removePermission: _vm.removeDocumentPermission,
                              updateVersionTag: _vm.updateVersionTag
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }