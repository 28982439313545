var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "d-flex flex-column documents-tree-card",
      attrs: { flat: "", tile: "" }
    },
    [
      _vm.editDocument === null
        ? _c("div", [
            _c("p", { staticClass: "ma-5", attrs: { centered: "" } }, [
              _vm._v("No Document selected")
            ])
          ])
        : _c(
            "div",
            [
              _c(
                "v-tabs",
                {
                  attrs: {
                    dark: "",
                    grow: "",
                    disabled: _vm.disableInput,
                    "background-color": "secondary"
                  },
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _c("v-tab", { attrs: { href: "#documentPropertiesTab" } }, [
                    _vm._v("Properties")
                  ]),
                  _c("v-tab", { attrs: { href: "#documentVersionsTab" } }, [
                    _vm._v("Versions")
                  ]),
                  _c("v-tab", { attrs: { href: "#documentAccessTab" } }, [
                    _vm._v("Access")
                  ])
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _c(
                    "v-tab-item",
                    { attrs: { value: "documentPropertiesTab" } },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "pb-3",
                              attrs: { "no-gutters": "" }
                            },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      flat: "",
                                      "solo-inverted": "",
                                      "persistent-hint": "",
                                      hint: "Display title of the document",
                                      label: "Title",
                                      disabled:
                                        _vm.disableInput ||
                                        !_vm.selectedDocument.userAccess
                                          .canUpdate
                                    },
                                    model: {
                                      value: _vm.editDocument.title,
                                      callback: function($$v) {
                                        _vm.$set(_vm.editDocument, "title", $$v)
                                      },
                                      expression: "editDocument.title"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "pb-3",
                              attrs: { "no-gutters": "" }
                            },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      flat: "",
                                      "solo-inverted": "",
                                      "persistent-hint": "",
                                      hint: "Description of the document",
                                      label: "Description",
                                      rows: "1",
                                      "auto-grow": "",
                                      disabled:
                                        _vm.disableInput ||
                                        !_vm.selectedDocument.userAccess
                                          .canUpdate
                                    },
                                    model: {
                                      value: _vm.editDocument.description,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editDocument,
                                          "description",
                                          $$v
                                        )
                                      },
                                      expression: "editDocument.description"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "pb-3 body-1",
                              attrs: { "no-gutters": "" }
                            },
                            [
                              _c("v-col", [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.copyDocumentLink()
                                          }
                                        }
                                      },
                                      [_vm._v("Copy Document Link")]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "pb-3",
                              attrs: { "no-gutters": "" }
                            },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.disciplines,
                                      label: "Disciplines",
                                      multiple: "",
                                      chips: "",
                                      filled: "",
                                      hint:
                                        "Disciplines associated with the document",
                                      "persistent-hint": "",
                                      dense: "",
                                      disabled:
                                        _vm.disableInput ||
                                        !_vm.selectedDocument.userAccess
                                          .canUpdate
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "selection",
                                        fn: function(data) {
                                          return [
                                            _c(
                                              "v-chip",
                                              _vm._b(
                                                {
                                                  key: JSON.stringify(
                                                    data.item
                                                  ),
                                                  staticClass: "ma-2",
                                                  attrs: {
                                                    "input-value":
                                                      data.selected,
                                                    disabled: data.disabled
                                                  },
                                                  on: {
                                                    "click:close": function(
                                                      $event
                                                    ) {
                                                      return data.parent.selectItem(
                                                        data.item
                                                      )
                                                    }
                                                  }
                                                },
                                                "v-chip",
                                                data.attrs,
                                                false
                                              ),
                                              [
                                                _c("v-avatar", {
                                                  staticClass:
                                                    "primary white--text",
                                                  attrs: { left: "" },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      data.item
                                                        .slice(0, 1)
                                                        .toUpperCase()
                                                    )
                                                  }
                                                }),
                                                _vm._v(
                                                  " " + _vm._s(data.item) + " "
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ]),
                                    model: {
                                      value: _vm.editDocument.disciplines,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editDocument,
                                          "disciplines",
                                          $$v
                                        )
                                      },
                                      expression: "editDocument.disciplines"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-combobox", {
                                    attrs: {
                                      label: "Tags",
                                      dense: "",
                                      multiple: "",
                                      flat: "",
                                      filled: "",
                                      disabled:
                                        _vm.disableInput ||
                                        !_vm.selectedDocument.userAccess
                                          .canUpdate
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "no-data",
                                        fn: function() {
                                          return [
                                            _c(
                                              "v-list-item",
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c("v-list-item-title", [
                                                      _vm._v(" Press "),
                                                      _c("kbd", [
                                                        _vm._v("enter")
                                                      ]),
                                                      _vm._v(' to set " '),
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(_vm.searchTag)
                                                        )
                                                      ]),
                                                      _vm._v('" as a tag ')
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        },
                                        proxy: true
                                      },
                                      {
                                        key: "selection",
                                        fn: function(data) {
                                          return [
                                            _c(
                                              "v-chip",
                                              _vm._b(
                                                {
                                                  key: JSON.stringify(
                                                    data.item
                                                  ),
                                                  staticClass: "ma-2",
                                                  attrs: {
                                                    "input-value":
                                                      data.selected,
                                                    disabled: data.disabled
                                                  },
                                                  on: {
                                                    "click:close": function(
                                                      $event
                                                    ) {
                                                      return data.parent.selectItem(
                                                        data.item
                                                      )
                                                    }
                                                  }
                                                },
                                                "v-chip",
                                                data.attrs,
                                                false
                                              ),
                                              [
                                                _c("v-avatar", {
                                                  staticClass:
                                                    "primary white--text",
                                                  attrs: {
                                                    left: "",
                                                    small: ""
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      data.item
                                                        .slice(0, 1)
                                                        .toUpperCase()
                                                    )
                                                  }
                                                }),
                                                _vm._v(
                                                  " " + _vm._s(data.item) + " "
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ]),
                                    model: {
                                      value: _vm.editDocument.tags,
                                      callback: function($$v) {
                                        _vm.$set(_vm.editDocument, "tags", $$v)
                                      },
                                      expression: "editDocument.tags"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _vm.selectedDocument.userAccess.canUpdate
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            depressed: "",
                                            color: "primary",
                                            disabled: _vm.disableInput,
                                            loading: _vm.disableInput
                                          },
                                          on: { click: _vm.saveProperties }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "pr-2" },
                                            [_vm._v("fas fa-save")]
                                          ),
                                          _vm._v("Save ")
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "body-2",
                                  staticStyle: { "text-align": "right" }
                                },
                                [
                                  _vm._v(" Updated by "),
                                  _c("i", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.selectedDocument.modifiedBy ||
                                          "User not found"
                                      )
                                    )
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatDate")(
                                          _vm.selectedDocument.modifiedOn
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "body-2",
                                  staticStyle: { "text-align": "right" }
                                },
                                [
                                  _vm._v(" Created by "),
                                  _c("i", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.selectedDocument.createdBy ||
                                          "User not found"
                                      )
                                    )
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatDate")(
                                          _vm.selectedDocument.createdOn
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { attrs: { value: "documentActivityTab" } },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-timeline",
                            _vm._l(_vm.sortedVersions, function(version) {
                              return _c(
                                "v-timeline-item",
                                {
                                  key: version.id,
                                  attrs: { small: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "opposite",
                                        fn: function() {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "headline font-weight-bold"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("formatDate")(
                                                      version.createdOn
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c("div", { staticClass: "py-4" }, [
                                    _c(
                                      "h2",
                                      {
                                        staticClass:
                                          "headline font-weight-light mb-4"
                                      },
                                      [_vm._v(_vm._s(version.versionTag))]
                                    ),
                                    _c("div", [
                                      _vm._v("by " + _vm._s(version.createdBy))
                                    ])
                                  ])
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { attrs: { value: "documentVersionsTab" } },
                    [
                      _c(
                        "v-container",
                        [
                          _c("v-data-table", {
                            staticClass: "elevation-0",
                            attrs: {
                              "calculate-widths": "",
                              headers: _vm.versionHeaders,
                              "sort-by": ["createdOn"],
                              "sort-desc": [true],
                              items: _vm.selectedDocument.versions,
                              "items-per-page": 100,
                              "item-key": "name",
                              "hide-default-footer": "",
                              "fixed-header": ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item.versionTag",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        flat: "",
                                        solo: "",
                                        dense: "",
                                        "hide-details": "",
                                        label: "Title",
                                        disabled:
                                          _vm.disableInput ||
                                          !_vm.selectedDocument.userAccess
                                            .canManageVersions,
                                        "append-icon": _vm.selectedDocument
                                          .userAccess.canManageVersions
                                          ? "mdi-content-save"
                                          : ""
                                      },
                                      on: {
                                        "click:append": function($event) {
                                          return _vm.saveVersionTag(item)
                                        }
                                      },
                                      model: {
                                        value: item.versionTag,
                                        callback: function($$v) {
                                          _vm.$set(item, "versionTag", $$v)
                                        },
                                        expression: "item.versionTag"
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "item.createdOn",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate")(item.createdOn)
                                      )
                                    )
                                  ]
                                }
                              },
                              {
                                key: "item.createdBy",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [_vm._v(_vm._s(item.createdBy))]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { attrs: { value: "documentAccessTab" } },
                    [
                      _c(
                        "v-container",
                        [
                          _c("v-switch", {
                            staticClass: "mx-2",
                            attrs: {
                              label: "Is Public",
                              hint:
                                "Sets the document to be viewable to all users",
                              color: "primary",
                              "persistent-hint": "",
                              loading: _vm.disableInput,
                              disabled: !_vm.selectedDocument.userAccess
                                .canManagePermissions
                            },
                            on: { change: _vm.updateIsPublic },
                            model: {
                              value: _vm.editDocument.isPublic,
                              callback: function($$v) {
                                _vm.$set(_vm.editDocument, "isPublic", $$v)
                              },
                              expression: "editDocument.isPublic"
                            }
                          }),
                          !_vm.editDocument.isPublic
                            ? _c(
                                "div",
                                [
                                  _vm.selectedDocument.userAccess
                                    .canManagePermissions
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "mt-3",
                                          attrs: {
                                            color: "primary",
                                            depressed: "",
                                            disabled:
                                              _vm.disableInput ||
                                              _vm.editDocument.isPublic
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.showNewPerimssion = true
                                            }
                                          }
                                        },
                                        [
                                          _c("v-icon", [_vm._v("mdi-plus")]),
                                          _vm._v("Add Permission ")
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-form",
                                    {
                                      ref: "permissionForm",
                                      model: {
                                        value: _vm.newPermissionValid,
                                        callback: function($$v) {
                                          _vm.newPermissionValid = $$v
                                        },
                                        expression: "newPermissionValid"
                                      }
                                    },
                                    [
                                      _vm.showNewPerimssion
                                        ? _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      items: ["Group", "Email"],
                                                      hint: "Access Type",
                                                      flat: "",
                                                      dense: "",
                                                      "solo-inverted": "",
                                                      "persistent-hint": "",
                                                      disabled: _vm.disableInput
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newPermissionType,
                                                      callback: function($$v) {
                                                        _vm.newPermissionType = $$v
                                                      },
                                                      expression:
                                                        "newPermissionType"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm.newPermissionType === "Group"
                                                ? _c(
                                                    "v-col",
                                                    [
                                                      _c("v-autocomplete", {
                                                        attrs: {
                                                          items:
                                                            _vm.permissionGroups,
                                                          "item-text": "name",
                                                          "item-value": "name",
                                                          hint:
                                                            "Type to select a group",
                                                          flat: "",
                                                          dense: "",
                                                          "solo-inverted": "",
                                                          "persistent-hint": "",
                                                          rules: [
                                                            function(v) {
                                                              return (
                                                                !!v ||
                                                                "Group is required"
                                                              )
                                                            }
                                                          ],
                                                          disabled:
                                                            _vm.disableInput
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newPermissionGroup,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.newPermissionGroup = $$v
                                                          },
                                                          expression:
                                                            "newPermissionGroup"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.newPermissionType === "Email"
                                                ? _c(
                                                    "v-col",
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          hint:
                                                            "Enter an Aileron account email",
                                                          flat: "",
                                                          "solo-inverted": "",
                                                          "persistent-hint": "",
                                                          dense: "",
                                                          rules: _vm.emailRule,
                                                          disabled:
                                                            _vm.disableInput
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newPermissionEmail,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.newPermissionEmail = $$v
                                                          },
                                                          expression:
                                                            "newPermissionEmail"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "v-col",
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        depressed: "",
                                                        disabled:
                                                          _vm.disableInput,
                                                        loading:
                                                          _vm.disableInput
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.saveNewPermission
                                                      }
                                                    },
                                                    [_vm._v("Save")]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "ml-1",
                                                      attrs: {
                                                        depressed: "",
                                                        disabled:
                                                          _vm.disableInput
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.showNewPerimssion = false
                                                          _vm.newPermissionGroup =
                                                            ""
                                                          _vm.newPermissionEmail =
                                                            ""
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("Cancel")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c("h3", { staticClass: "mt-2" }, [
                                    _vm._v("Groups")
                                  ]),
                                  _c("v-data-table", {
                                    staticClass: "elevation-0",
                                    attrs: {
                                      "calculate-widths": "",
                                      headers: _vm.permissionHeaders,
                                      "sort-by": ["createdOn"],
                                      "sort-desc": [true],
                                      items: _vm.groupPermissions,
                                      "items-per-page": 100,
                                      "item-key": "name",
                                      "hide-default-footer": "",
                                      "fixed-header": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item.id",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    icon: "",
                                                    disabled:
                                                      _vm.disableInput ||
                                                      _vm.editDocument.isPublic
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.openRemovePermission(
                                                        item
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-delete")
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2973965347
                                    )
                                  }),
                                  _c("h3", [_vm._v("Users")]),
                                  _c("v-data-table", {
                                    staticClass: "elevation-0",
                                    attrs: {
                                      "calculate-widths": "",
                                      headers: _vm.permissionHeaders,
                                      "sort-by": ["createdOn"],
                                      "sort-desc": [true],
                                      items: _vm.userPermissions,
                                      "items-per-page": 100,
                                      "item-key": "name",
                                      "hide-default-footer": "",
                                      "fixed-header": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item.id",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _vm.selectedDocument.userAccess
                                                .canManagePermissions
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        color: "primary",
                                                        disabled:
                                                          _vm.disableInput ||
                                                          _vm.editDocument
                                                            .isPublic
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.openRemovePermission(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("mdi-delete")
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1675601914
                                    )
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-dialog",
                            {
                              attrs: { "max-width": "500" },
                              model: {
                                value: _vm.showRemovePermission,
                                callback: function($$v) {
                                  _vm.showRemovePermission = $$v
                                },
                                expression: "showRemovePermission"
                              }
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-overlay",
                                    {
                                      attrs: {
                                        absolute: "",
                                        value: _vm.disableInput
                                      }
                                    },
                                    [
                                      _c("v-progress-circular", {
                                        staticStyle: {
                                          display: "inline-block"
                                        },
                                        attrs: {
                                          indeterminate: "",
                                          color: "primary"
                                        }
                                      }),
                                      _c(
                                        "h2",
                                        {
                                          staticStyle: {
                                            display: "inline-block",
                                            "margin-left": "10px",
                                            "verticle-align": "middle"
                                          }
                                        },
                                        [_vm._v("Removing permission")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-title",
                                    { staticClass: "headline" },
                                    [_vm._v("Remove permission")]
                                  ),
                                  _c("v-container", [
                                    _c("span", { staticClass: "body-2" }, [
                                      _vm._v(
                                        "Are you sure you want to remove permission for " +
                                          _vm._s(
                                            _vm.selectedPermission.roleName
                                          ) +
                                          "?"
                                      )
                                    ])
                                  ]),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "primary " },
                                          on: { click: _vm.removePermission }
                                        },
                                        [_vm._v("Yes")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "" },
                                          on: {
                                            click: function($event) {
                                              _vm.showRemovePermission = false
                                            }
                                          }
                                        },
                                        [_vm._v("Cancel")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }