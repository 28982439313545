import * as tslib_1 from "tslib";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import moment from "moment";
import { DISCIPLINES } from "@/views/admin/CMSConstants";
const editableProps = ["title", "description", "disciplines", "tags"];
let FolderProperties = class FolderProperties extends Vue {
    constructor() {
        super();
        this.editFolder = null;
        this.searchTag = null;
        this.showImageUrl = false;
        this.disciplines = DISCIPLINES;
        this.tab = null;
        this.searchTag = null;
    }
    mounted() {
        this.intializeProperties(this.selectedFolder);
    }
    intializeProperties(folderProps) {
        const imageResult = this.imageOptions.find(io => io.fileUrl === folderProps.imageUrl);
        if (imageResult != null) {
            this.showImageUrl = false;
        }
        else if (folderProps.imageUrl !== "" && folderProps.imageUrl != null) {
            this.showImageUrl = true;
        }
        else {
            this.showImageUrl = false;
        }
        this.editFolder = folderProps
            ? {
                imageUrl: folderProps.imageUrl,
                title: folderProps.title,
                description: folderProps.description,
                disciplines: folderProps.disciplines,
                tags: folderProps.tags,
                featured: folderProps.featured
            }
            : {};
    }
    get editFolderModified() {
        let modified = false;
        if (this.selectedFolder) {
            if ((this.selectedFolder.title === null ? "" : this.selectedFolder.title) !=
                (this.editFolder.title === null ? "" : this.editFolder.title)) {
                modified = true;
            }
            else if ((this.selectedFolder.description === null
                ? ""
                : this.selectedFolder.description) !=
                (this.editFolder.description === null
                    ? ""
                    : this.editFolder.description)) {
                modified = true;
            }
            else if (this.selectedFolder.disciplines != this.editFolder.disciplines) {
                modified = true;
            }
            else if (this.selectedFolder.tags != this.editFolder.tags) {
                modified = true;
            }
        }
        return modified;
    }
    saveProperties() {
        this.$emit("updatefolder", this.editFolder);
    }
};
tslib_1.__decorate([
    Prop()
], FolderProperties.prototype, "selectedFolder", void 0);
tslib_1.__decorate([
    Prop()
], FolderProperties.prototype, "disableInput", void 0);
tslib_1.__decorate([
    Prop()
], FolderProperties.prototype, "imageOptions", void 0);
FolderProperties = tslib_1.__decorate([
    Component({
        name: "FolderProperties",
        components: {},
        watch: {
            selectedFolder: function (newFolder, oldFolder) {
                this.intializeProperties(newFolder);
            }
        },
        filters: {
            formatDate(date) {
                return moment(date).fromNow();
            }
        }
    })
], FolderProperties);
export default FolderProperties;
